import React, { useContext } from 'react';
import { Dropdown } from '@epam/uui-components';
import {
  Badge,
  SuccessNotification,
  DropdownContainer,
  EpamAdditionalColor,
  FlexRow,
  FlexCell,
  LabeledInput,
} from '@epam/promo';
import { DropdownBodyProps } from '@epam/uui-core';
import { IDropdownToggler, useUuiContext } from '@epam/uui';
import checkIcon from 'assets/svg/check.svg';
import { checkDisabledStatus } from '../../../../utils';
import { EditorSwitchContext, queryClient } from '../../../../../../App';

import {
  NOTIFICATION_MESSAGES,
  PROJECT_STATUS,
  PROJECT_STATUS_COLOR,
} from '../../../../../../constants/uiConstants';
import './styles.scss';
import { AlertModal } from '../../../../../../component/AlertModal';

import { useMutation } from '@tanstack/react-query';
import projectManagementService from '../../../../../../services/api/project-management/projectManagementService';
import { QUERY_KEYS } from '../../../../../../constants/queryKeys';
import { DefaultError } from 'component/notification/DefaultError';
import { BlockerModal } from 'component/BlockerModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { formatDate } from 'modules/project-management/update/utils';
import ProjectEndDate from './ProjectEndDate';

export type Props = {
  id: string;
  projectStatus: string;
  options: string[];
  userHasEditPermissions: boolean;
  isProjectHasActiveShowroomRequest: boolean;
  closeParentModal: () => void;
  canBeMarkedForDeletion: boolean;
};

const ProjectStatus: React.FC<Props> = ({
  projectStatus: currentStatus,
  options: listOfStatuses,
  id: projectId,
  userHasEditPermissions,
  isProjectHasActiveShowroomRequest,
  closeParentModal,
  canBeMarkedForDeletion,
}) => {
  const isEditorModeEnabled = useContext(EditorSwitchContext);
  const svc = useUuiContext();
  let endDate = '';
  const setProjectEndDate = (data: string) => {
    endDate = data;
  };
  const deleteProjectMutation = useMutation<any, any, number>(
    (id: number) => {
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);
      return projectManagementService.markProjectForDeletion(id);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
        ]);

        useCloseBlocker(svc);
        closeParentModal();
        svc.uuiNotifications
          .show((props) => (
            <SuccessNotification {...props}>
              {NOTIFICATION_MESSAGES.success.isMarkedForDeletion}
            </SuccessNotification>
          ))
          .catch(() => null);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );
  const updateProjectEndDateMutation = useMutation<
    any,
    any,
    any,
    { id: number; projectEndDate: string; updatedStatus: string }
  >(
    ({ id, projectEndDate }) => {
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);
      return projectManagementService.updateProjectEndDate(
        id,
        formatDate(projectEndDate)
      );
    },
    {
      onSuccess: async () => {
        updateProjectStatusMutation.mutate({
          id: parseInt(projectId),
          status: 'ACTIVE',
        });
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);

        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );
  const updateProjectStatusMutation = useMutation<
    any,
    any,
    { id: number; status: string }
  >(
    ({ id, status }) => {
      svc.uuiModals
        .show((props) => <BlockerModal modalProps={props} />, {
          modalId: 'blocker',
        })
        .catch(() => null);
      return projectManagementService.updateProjectStatus(id, status);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([
            QUERY_KEYS.PROJECTS.PROJECT_DETAILS,
            projectId,
          ]),
          queryClient.refetchQueries([QUERY_KEYS.PROJECTS.PROJECT_LIST]),
          queryClient.refetchQueries([QUERY_KEYS.USERS.PROJECT_COUNT]),
          queryClient.refetchQueries([
            QUERY_KEYS.OPPORTUNITIES.OPPORTUNITY_LIST,
          ]),
        ]);
        useCloseBlocker(svc);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const handleStatusChange = async (newProjectStatus: string) => {
    if (newProjectStatus === 'DELETE') {
      if (canBeMarkedForDeletion) {
        svc.uuiModals
          .show((props) => (
            <AlertModal
              modalProps={props}
              alertContent={{
                title: 'Delete project? ',
                description:
                  'The project will be marked for deletion and will not be available from the Dashboard. Project gitlab repository will be archived.',
                closeText: 'Delete project',
                cancelBtn: true,
                noBorder: true,
              }}
            />
          ))
          .then(() => {
            deleteProjectMutation.mutate(parseInt(projectId));
          })
          .catch(() => useCloseBlocker(svc));
      } else {
        svc.uuiModals
          .show((props) => (
            <AlertModal
              modalProps={props}
              alertContent={{
                title: 'Cannot delete project ',
                description:
                  'Please remove all team members before deleting the project.',
                closeText: 'Got it',
                noBorder: true,
              }}
            />
          ))
          .then(() => useCloseBlocker(svc))
          .catch(() => useCloseBlocker(svc));
      }
    } else if (currentStatus === 'SUSPENDED' && newProjectStatus === 'ACTIVE') {
      svc.uuiModals
        .show((props) => {
          return (
            <AlertModal
              modalProps={props}
              alertContent={{
                title: 'Your Project End Date is outdated ',
                description: (
                  <>
                    <div>Please update the Project End Date.</div>
                    <FlexRow vPadding={'24'} cx={'project-date-container'}>
                      <FlexCell
                        width={'auto'}
                        rawProps={{ 'data-auto-scroll': '' }}
                      >
                        <LabeledInput
                          label={'Project End Date'}
                          isRequired={true}
                        >
                          <ProjectEndDate projectEndDate={setProjectEndDate} />
                        </LabeledInput>
                      </FlexCell>
                    </FlexRow>
                  </>
                ),
                closeText: 'Save',
                cancelBtn: true,
                noBorder: true,
              }}
            />
          );
        })
        .then(() =>
          updateProjectEndDateMutation.mutate({
            id: parseInt(projectId),
            projectEndDate: endDate,
            status: newProjectStatus,
          })
        )
        .catch(() => useCloseBlocker(svc));
    } else {
      updateProjectStatusMutation.mutate({
        id: parseInt(projectId),
        status: newProjectStatus,
      });
    }
  };

  // DropdownBodyProps has been removed as a type export from uui-components
  const renderDropdownBody = (props: DropdownBodyProps) => {
    return (
      <DropdownContainer width={140}>
        {listOfStatuses
          .filter(
            (status) =>
              !checkDisabledStatus(currentStatus, status, isEditorModeEnabled)
          )
          .map((status) => (
            <div
              key={status}
              className="dropdown-container"
              onClick={() => {
                if (
                  status == 'SUSPENDED' &&
                  isProjectHasActiveShowroomRequest
                ) {
                  svc.uuiModals.show((props) => (
                    <AlertModal
                      modalProps={props}
                      alertContent={{
                        title: 'Exception',
                        description:
                          'The showroom request should be revoked first.',
                      }}
                    />
                  ));
                } else {
                  if (props.onClose) {
                    props.onClose();
                  }
                  handleStatusChange(status);
                }
              }}
            >
              <div className="dropdown__item" data-testid="projectStatus">
                <span style={{ color: status === 'DELETE' ? '#BE3316' : '' }}>
                  {PROJECT_STATUS[status as keyof typeof PROJECT_STATUS]}
                </span>
              </div>
              {currentStatus === status && <img src={checkIcon} alt="check" />}
            </div>
          ))}
      </DropdownContainer>
    );
  };

  return (
    <Dropdown
      isNotUnfoldable={
        deleteProjectMutation.isLoading ||
        updateProjectStatusMutation.isLoading ||
        !userHasEditPermissions
      }
      renderBody={renderDropdownBody}
      renderTarget={(props: IDropdownToggler) => {
        const p = userHasEditPermissions && props;
        return (
          <Badge
            {...p}
            caption={
              PROJECT_STATUS[currentStatus as keyof typeof PROJECT_STATUS]
            }
            fill={'semitransparent'}
            color={
              PROJECT_STATUS_COLOR[
                currentStatus as keyof typeof PROJECT_STATUS_COLOR
              ] as EpamAdditionalColor
            }
            size={'30'}
            isDropdown={userHasEditPermissions}
          />
        );
      }}
    />
  );
};

export default ProjectStatus;
