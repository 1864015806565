import { useUuiContext } from '@epam/uui';
import { BlockerModal } from 'component/BlockerModal';
import useNotificationsModal from 'component/NotifiactionsModal';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { ModalFooter, FlexSpacer, Button } from '@epam/promo';
import { AddToShowroomFooterProps } from './models/interfaces';
import { LINK_TYPES } from '../project-links/constants';
import { SHOWROOM_VALIDATION_ERRORS } from './models/validationSchema';
import { getLinkByType, getLinksWithProps } from './helpers/utils';
import { useLensProps } from 'hooks/useLens';
import {
  requiredArtifactsFormLinksTypes,
  optionalArtifactsFormLinksTypes,
} from './helpers/utils';
import ProjectManagementService from 'services/api/project-management/projectManagementService';
import { useUserInfo } from 'services/auth-client/user-info-provider';
import {
  isUserWithEditorRole,
  isUserWithEventOrganizerRole,
} from 'utils/rolesUtils';

const Footer = ({
  lens,
  validate,
  saveForm,
  setNextStep,
  setPreviousStep,
  projectId,
  projectLinks,
  isNotLastStep,
}: AddToShowroomFooterProps) => {
  const editorSwitch = localStorage.getItem('editorSwitch');
  const { userInfo } = useUserInfo();
  const isEditor: boolean = isUserWithEditorRole(userInfo);
  const isEventOrganizer: boolean = isUserWithEventOrganizerRole(userInfo);
  const { GIT_OTHER } = LINK_TYPES;
  const svc = useUuiContext();
  const { setProp } = useLensProps(lens);
  const requiredArtifactsLinks = getLinksWithProps(
    lens,
    requiredArtifactsFormLinksTypes
  );
  const optionalArtifactsLinks = getLinksWithProps(
    lens,
    optionalArtifactsFormLinksTypes
  );
  let isEditorUser = false;
  const checkReqFields = () => {
    return projectLinks
      .filter(
        (item) =>
          item.linkType === 'DOCS_DECKS' || item.linkType === 'SLIDE_DECK'
      )
      .map((item) => {
        isEditorUser =
          (isEditor || isEventOrganizer) && JSON.parse(editorSwitch as string);
        if (isEditorUser) {
          return true;
        } else {
          if (item.linkType === 'DOCS_DECKS') {
            return item.link.length > 0;
          }
          if (item.linkType === 'SLIDE_DECK') {
            const sdFileExist =
              item?.file instanceof File || item?.link?.length > 0;
            return sdFileExist;
          }
        }
      })
      .every((val) => val === true);
  };

  const { errorNotification } = useNotificationsModal();
  return (
    <ModalFooter borderTop cx="modal-footer">
      <FlexSpacer />
      {isNotLastStep ? (
        <Button color="blue" caption="Next" onClick={setNextStep} />
      ) : (
        <>
          <Button
            color="gray50"
            fill="white"
            caption="Back"
            onClick={setPreviousStep}
          />
          <Button
            color="green"
            caption="Add to Showroom"
            onClick={async () => {
              const reqField = checkReqFields();
              const userEpamGitlabLink = getLinkByType(projectLinks, GIT_OTHER);
              svc.uuiModals
                .show((props) => <BlockerModal modalProps={props} />, {
                  modalId: 'blocker',
                })
                .catch(() => null);
              let userHasActiveRepository;
              try {
                userHasActiveRepository =
                  await ProjectManagementService.getFieldsStatusToAddProjectToShowroom(
                    projectId,
                    !!userEpamGitlabLink && userEpamGitlabLink.link
                  );
                if ((isEditor || isEventOrganizer) && editorSwitch) {
                  userHasActiveRepository = true;
                }
                setProp('BE_ERROR_MESSAGE', '');
              } catch (err) {
                userHasActiveRepository = false;
                setProp(
                  'BE_ERROR_MESSAGE',
                  SHOWROOM_VALIDATION_ERRORS.INVALID_EPAM_GITLAB_LINK // hardcoding error message due to current FE axios limitation of reading all of BE error messages. Actual BE error message should be read from the response once axios error message handling logic is fixed in the ticket https://jira.epam.com/jira/browse/EPMEMRDGD-64
                );
              }
              setProp('userHasActiveRepository', userHasActiveRepository);
              validate();
              useCloseBlocker(svc);
              if (
                requiredArtifactsLinks.some(
                  (link: any) => link.toProps().isInvalid
                ) ||
                !userHasActiveRepository ||
                !reqField
              ) {
                setPreviousStep();
                errorNotification(`Validation Errors.`);
              } else if (
                requiredArtifactsLinks.some(
                  (link: any) => !link.toProps().isInvalid
                ) &&
                optionalArtifactsLinks.some(
                  (link: any) => !link.toProps().isInvalid
                )
              ) {
                saveForm();
              }
            }}
          />
        </>
      )}
    </ModalFooter>
  );
};

export default Footer;
