import { Metadata } from '@epam/uui';
import { EventIF } from '../models/interfaces';
import {
  EVENT_DESCRIPTION_CHAR_LIMIT,
  EVENT_NAME_CHAR_LIMIT,
} from '../../../constants/uiConstants';
import { buildDate, compareTimes } from './utils';
import {
  validURLRegExp,
  validEventName,
  emailRegExp,
} from 'constants/regexpConstants';
import dayjs from 'dayjs';

export const VALIDATION_ERRORS = Object.freeze({
  FIELD_IS_REQUIRED: 'This field is required.',
  CHARACTER_LIMIT: 'The character limit is exceeded.',
  EVENT_NAME_TOO_SHORT: 'Event name must be at least 2 characters long.',
  EVENT_NAME_EXISTS:
    'This event name already exists. Please try again with a different name.',
  EVENT_NAME_ALLOWED_CHARACTERS: `Event name can contain only letters, digits, spaces and the symbols: _ . : ; ! ? @ # & ( ) < > [ ] { } ' + -`,
  LOCATION_OR_GLOBAL_IS_REQUIRED: 'Please enter "Location" or check "Global".',
  REGISTRATION_START_DATE_AFTER_REGISTRATION_END_DATE:
    'Registration Start Date cannot be later than the Registration End Date.',
  REGISTRATION_START_TIME_AFTER_OR_EQUAL_TO_REGISTRATION_END_TIME:
    'Registration Start Time cannot be later or equal to Registration End Time.',
  REGISTRATION_END_DATE_BEFORE_REGISTRATION_START_DATE:
    'Registration End Date cannot be earlier than the Registration Start Date.',
  REGISTRATION_END_TIME_BEFORE_CURRENT_TIME:
    'Registration End Time cannot be earlier than the current time.',
  REGISTRATION_END_DATE_BEFORE_CURRENT_DATE:
    'Registration End Date cannot be earlier than the current date.',
  REGISTRATION_END_TIME_BEFORE_OR_EQUAL_TO_REGISTRATION_START_TIME:
    'Registration End Time cannot be earlier or equal to the Registration Start Time.',
  REGISTRATION_END_DATE_AFTER_DURATION_END_DATE:
    'Registration End Date cannot be later than Duration End Date.',
  REGISTRATION_END_TIME_AFTER_DURATION_END_TIME:
    'Registration End Time cannot be later than Duration End Time.',
  DURATION_START_DATE_AFTER_DURATION_END_DATE:
    'Duration Start Date cannot be later than the Duration End Date.',
  DURATION_START_TIME_AFTER_DURATION_END_TIME:
    'Duration Start Time cannot be later than or equal to the Duration End Time.',
  DURATION_START_DATE_BEFORE_REGISTRATION_START_DATE:
    'Duration Start Date cannot be earlier than the Registration Start Date.',
  DURATION_START_TIME_BEFORE_REGISTRATION_START_TIME:
    'Duration Start Time cannot be earlier than the Registration Start Time.',
  DURATION_END_DATE_BEFORE_DURATION_START_DATE:
    'Duration End Date cannot be earlier than the Duration Start Date.',
  DURATION_END_DATE_BEFORE_REGISTRATION_END_DATE:
    'Duration End Date cannot be earlier than the Registration End Date.',
  DURATION_END_TIME_BEFORE_REGISTRATION_END_TIME:
    'Duration End Time cannot be earlier than the Registration End Time.',
  RESULT_DEADLINE_BEFORE_DURATION_START_DATE:
    'Deadline for results submission cannot be earlier than the Duration Start Date.',
  RESULT_DEADLINE_AFTER_DURATION_END_DATE:
    'Deadline for results submission cannot be later than the Duration End Date.',
  RESULT_DEADLINE_TIME_BEFORE_CURRENT_TIME:
    'Deadline for results submission Time cannot be earlier than the current time.',
  RESULT_DEADLINE_DATE_BEFORE_CURRENT_DATE:
    'Deadline for results submission Date cannot be earlier than the current date.',
  DUPLICATE_HOME_SITE_LINK:
    'This Home Site URL already exists. Please try again with a different URL.',
  INVALID_HOME_SITE_LINK:
    'Link does not have a proper URL pattern i.e. http://example.com.',
  INVALID_EMAIL: 'Email should correspond to the format i.e. sample@domain.com',
});

export const eventSchema = (event: EventIF): Metadata<EventIF> => ({
  props: {
    name: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (val.length === 1) return [VALIDATION_ERRORS.EVENT_NAME_TOO_SHORT];
          if (val && val.trim().length == 0)
            return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (val && val.length > EVENT_NAME_CHAR_LIMIT)
            return [VALIDATION_ERRORS.CHARACTER_LIMIT];
          if (!validEventName.test(val))
            return [VALIDATION_ERRORS.EVENT_NAME_ALLOWED_CHARACTERS];
          return [false];
        },
      ],
    },
    description: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (val && val.trim().length == 0)
            return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (val && val.length > EVENT_DESCRIPTION_CHAR_LIMIT)
            return [VALIDATION_ERRORS.CHARACTER_LIMIT];
          return [false];
        },
      ],
    },
    timezone: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          return [false];
        },
      ],
    },
    homeSiteUrl: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (val && val.trim().length == 0)
            return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (RegExp(validURLRegExp).test(val) === false)
            return [VALIDATION_ERRORS.INVALID_HOME_SITE_LINK];
          return [false];
        },
      ],
    },
    organizersExternalId: {
      validators: [
        (val: string[]) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (!val.length) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          return [false];
        },
      ],
    },
    location: {
      validators: [
        (val: any) => {
          if (!val && !event.global)
            return [VALIDATION_ERRORS.LOCATION_OR_GLOBAL_IS_REQUIRED];
          return [false];
        },
      ],
    },
    supportEmail: {
      validators: [
        (val: any) => {
          if (!val) return [false];
          if (RegExp(emailRegExp).test(val) === false)
            return [VALIDATION_ERRORS.INVALID_EMAIL];
          return [false];
        },
      ],
    },
    eventStartDate: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.eventEndDate &&
            buildDate(val).isAfter(buildDate(event.eventEndDate))
          )
            return [
              VALIDATION_ERRORS.DURATION_START_DATE_AFTER_DURATION_END_DATE,
            ];
          return [false];
        },
      ],
    },
    eventStartTime: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.eventStartDate &&
            event.eventEndDate &&
            event.eventEndTime &&
            buildDate(event.eventStartDate).isSame(
              buildDate(event.eventEndDate)
            ) &&
            (buildDate(event.eventStartDate, val).isAfter(
              buildDate(event.eventEndDate, event.eventEndTime)
            ) ||
              buildDate(event.eventStartDate, val).isSame(
                buildDate(event.eventEndDate, event.eventEndTime)
              ))
          )
            return [
              VALIDATION_ERRORS.DURATION_START_TIME_AFTER_DURATION_END_TIME,
            ];
          return [false];
        },
      ],
    },
    eventEndDate: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.eventStartDate &&
            buildDate(val).isBefore(buildDate(event.eventStartDate))
          )
            return [
              VALIDATION_ERRORS.DURATION_END_DATE_BEFORE_DURATION_START_DATE,
            ];
          if (
            val &&
            event.registrationEndDate &&
            buildDate(val).isBefore(buildDate(event.registrationEndDate))
          )
            return [
              VALIDATION_ERRORS.DURATION_END_DATE_BEFORE_REGISTRATION_END_DATE,
            ];
          return [false];
        },
      ],
    },
    eventEndTime: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.eventEndDate &&
            event.registrationEndDate &&
            event.registrationEndTime &&
            buildDate(event.eventEndDate).isSame(
              buildDate(event.registrationEndDate)
            ) &&
            buildDate(event.eventEndDate, val).isBefore(
              buildDate(event.registrationEndDate, event.registrationEndTime)
            ) &&
            event.eventStartTime &&
            buildDate(event.eventEndDate, val).isAfter(
              buildDate(event.eventStartDate, event.eventStartTime)
            )
          )
            return [
              VALIDATION_ERRORS.DURATION_END_TIME_BEFORE_REGISTRATION_END_TIME,
            ];
          return [false];
        },
      ],
    },
    registrationStartDate: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.registrationEndDate &&
            new Date(val).setHours(0, 0, 0, 0) >
              new Date(event.registrationEndDate).setHours(0, 0, 0, 0)
          )
            return [
              VALIDATION_ERRORS.REGISTRATION_START_DATE_AFTER_REGISTRATION_END_DATE,
            ];
          return [false];
        },
      ],
    },
    registrationStartTime: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            new Date(event.registrationEndDate)?.setHours(0, 0, 0, 0) ===
              new Date(event.registrationStartDate)?.setHours(0, 0, 0, 0) &&
            compareTimes(event.registrationStartTime, event.registrationEndTime)
          )
            return [
              VALIDATION_ERRORS.REGISTRATION_START_TIME_AFTER_OR_EQUAL_TO_REGISTRATION_END_TIME,
            ];
          return [false];
        },
      ],
    },
    registrationEndDate: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.registrationStartDate &&
            new Date(val).setHours(0, 0, 0, 0) <
              new Date(event.registrationStartDate).setHours(0, 0, 0, 0)
          )
            return [
              VALIDATION_ERRORS.REGISTRATION_END_DATE_BEFORE_REGISTRATION_START_DATE,
            ];
          if (
            val &&
            event.isNewEvent &&
            event.registrationEndTime &&
            dayjs() > buildDate(val, event.registrationEndTime)
          )
            return [
              VALIDATION_ERRORS.REGISTRATION_END_DATE_BEFORE_CURRENT_DATE,
            ];
          if (
            val &&
            event.eventEndDate &&
            buildDate(val).isAfter(buildDate(event.eventEndDate))
          ) {
            return [
              VALIDATION_ERRORS.REGISTRATION_END_DATE_AFTER_DURATION_END_DATE,
            ];
          }
          return [false];
        },
      ],
    },
    registrationEndTime: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            new Date(event.registrationEndDate)?.setHours(0, 0, 0, 0) ===
              new Date(event.registrationStartDate)?.setHours(0, 0, 0, 0) &&
            compareTimes(event.registrationStartTime, event.registrationEndTime)
          )
            return [
              VALIDATION_ERRORS.REGISTRATION_END_TIME_BEFORE_OR_EQUAL_TO_REGISTRATION_START_TIME,
            ];
          if (
            val &&
            event.registrationEndDate &&
            event.eventEndDate &&
            event.eventEndTime &&
            buildDate(event.registrationEndDate, val).isAfter(
              buildDate(event.eventEndDate, event.eventEndTime)
            )
          ) {
            return [
              VALIDATION_ERRORS.REGISTRATION_END_TIME_AFTER_DURATION_END_TIME,
            ];
          }
          return [false];
        },
      ],
    },
    deadlineDate: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.isNewEvent &&
            new Date().setHours(0, 0, 0, 0) > new Date(val).setHours(0, 0, 0, 0)
          )
            return [VALIDATION_ERRORS.RESULT_DEADLINE_DATE_BEFORE_CURRENT_DATE];
          if (
            val &&
            new Date(val).setHours(0, 0, 0, 0) >
              new Date(event.eventEndDate).setHours(0, 0, 0, 0)
          )
            return [VALIDATION_ERRORS.RESULT_DEADLINE_AFTER_DURATION_END_DATE];
          return [false];
        },
      ],
    },
    deadlineTime: {
      validators: [
        (val: any) => {
          if (!val) return [VALIDATION_ERRORS.FIELD_IS_REQUIRED];
          if (
            val &&
            event.deadlineDate &&
            event.isNewEvent &&
            dayjs() > buildDate(event.deadlineDate, val)
          )
            return [VALIDATION_ERRORS.RESULT_DEADLINE_TIME_BEFORE_CURRENT_TIME];
          if (
            val &&
            event.deadlineDate &&
            event.eventEndDate &&
            event.eventEndTime &&
            buildDate(event.deadlineDate, val).isAfter(
              buildDate(event.eventEndDate, event.eventEndTime)
            )
          )
            return [VALIDATION_ERRORS.RESULT_DEADLINE_AFTER_DURATION_END_DATE];
          if (
            val &&
            event.deadlineDate &&
            event.eventStartDate &&
            event.eventStartTime &&
            buildDate(event.deadlineDate, val).isBefore(
              buildDate(event.eventStartDate, event.eventStartTime)
            )
          )
            return [
              VALIDATION_ERRORS.RESULT_DEADLINE_BEFORE_DURATION_START_DATE,
            ];
          return [false];
        },
      ],
    },
  },
});
