import React, { useEffect, useState } from 'react';
import './styles.scss';
import {
  Button,
  Dropdown,
  DropdownMenuBody,
  DropdownMenuButton,
  SuccessNotification,
  Text,
} from '@epam/promo';
import { useUuiContext, IModal } from '@epam/uui';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DefaultError } from 'component/notification/DefaultError';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import opportunityService from 'services/api/opportunity/opportunityService';
import { useDictionary } from 'services/dictionary/dictionary-provider';
import SubscriptionsModal from 'modules/list-opportunities/components/subscriptions-modal';
import { NOTIFICATION_MESSAGES } from 'constants/uiConstants';
import { queryClient } from 'App';
import { AlertModal } from 'component/AlertModal';
import { BlockerModal } from 'component/BlockerModal';
import {
  isUserWithEditorRole,
  isUserWithEventOrganizerRole,
} from 'utils/rolesUtils';
import { useUserInfo } from 'services/auth-client/user-info-provider';

export function OpportunitySubscriptionButton() {
  const svc = useUuiContext();
  const { projectPositions } = useDictionary();
  const [subscriptions, setSubscriptions] = useState<any>([]);
  const { userInfo } = useUserInfo();
  const isEditor: boolean = isUserWithEditorRole(userInfo);
  const isEventOrganizer: boolean = isUserWithEventOrganizerRole(userInfo);
  const { data, isLoading } = useQuery<string[]>(
    [QUERY_KEYS.OPPORTUNITIES.SUBSCRIPTION_LIST],
    () => opportunityService.getSubscription(),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
      enabled: !!projectPositions && projectPositions.items.length > 0,
    }
  );

  useEffect(() => {
    const subscriptionObject = {
      positions: projectPositions?.items,
      subscriptions: data,
    };
    setSubscriptions(subscriptionObject);
  }, [projectPositions, data]);

  const unsubscibeMutation = useMutation<any, any>(
    () => opportunityService.editSubscription([]),
    {
      onSuccess: async () => {
        useCloseBlocker(svc);
        await queryClient.refetchQueries([
          QUERY_KEYS.OPPORTUNITIES.SUBSCRIPTION_LIST,
        ]);
        svc.uuiNotifications
          .show(
            (props) => (
              <SuccessNotification {...props}>
                <Text>{NOTIFICATION_MESSAGES.success.changedSubscription}</Text>
              </SuccessNotification>
            ),
            { duration: 5 }
          )
          .catch(() => null);
      },
      onError: (error) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  const unsubscribe = () => {
    svc.uuiModals
      .show((confirmModalProps: IModal<string>) => (
        <AlertModal
          modalProps={confirmModalProps}
          alertContent={{
            title: 'Unsubscribe?',
            noBorder: true,
            description:
              'Do you want to unsubscribe from receiving all notifications about new opportunities you picked?',
            closeText: 'Unsubscribe',
            cancelBtn: true,
          }}
        />
      ))
      .then(() => {
        svc.uuiModals
          .show((props) => <BlockerModal modalProps={props} />, {
            modalId: 'blocker',
          })
          .catch(() => null);
        unsubscibeMutation.mutate();
      })
      .catch(() => null);
  };

  const openSubscriptionsModal = () => {
    svc.uuiModals
      .show((modalProps: IModal<string>) => (
        <SubscriptionsModal
          modalProps={modalProps}
          subscription={subscriptions}
        />
      ))
      .catch(() => null);
  };

  const generateReport = () => {
    return null;
  };

  return (
    <div style={{ display: 'flex', columnGap: '12px' }}>
      {isEditor && isEventOrganizer && (
        <Button
          size="36"
          color="blue"
          caption="Generate Report"
          fill="white"
          onClick={generateReport}
        />
      )}
      {!isLoading &&
        (data?.length === 0 ? (
          <Button
            size="36"
            color="blue"
            caption="Subscribe"
            fill="white"
            onClick={openSubscriptionsModal}
          />
        ) : (
          <Dropdown
            renderBody={(props) => (
              <DropdownMenuBody {...props} cx={'subscribe-button-ddl'}>
                <DropdownMenuButton
                  caption="Edit subscription"
                  onClick={openSubscriptionsModal}
                />
                <DropdownMenuButton
                  caption="Unsubscribe"
                  onClick={unsubscribe}
                />
              </DropdownMenuBody>
            )}
            renderTarget={(props) => (
              <Button
                size="36"
                color="blue"
                caption="Edit subscription"
                fill="white"
                {...props}
              />
            )}
          />
        ))}
    </div>
  );
}
