import React, { useContext, useEffect, useState } from 'react';
import { Button, FlexRow, FlexSpacer, Panel, Text } from '@epam/promo';
import { useAsyncDataSource, useUuiContext, IModal } from '@epam/uui';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-12.svg';
import {
  ProjectActions,
  ProjectPosition,
  ProjectShowroomStatus,
  ProjectStatus as EnumProjectStatus,
} from 'models/enums';
import { IProjectMember } from 'modules/project-list/components/project-card/modal';
import { EventResponse } from 'modules/events/models/interfaces';
import './styles.scss';
import {
  getUniqMemberLocations,
  sortMembers,
} from '../../../utils/memberUtils';
import { Project } from '../../project-list/models/interfaces';
import { toProjectCardProps } from '../../project-list/utils';
import { ShowroomButtons } from './components/add-to-showroom/showroom-buttons';
import { ProjectDetailsOpportunities } from './components/add-opportunities/project-details-opportunities';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { UpdateProject } from '../update';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useCloseBlocker } from 'hooks/useCloseBlocker';
import { DefaultError } from 'component/notification/DefaultError';
import { useQuery } from '@tanstack/react-query';
import { ProjectPermissions } from 'services/api/permission/projectPermissions';
import { getProjectRole } from './utils';
import schedule from 'assets/svg/schedule.svg';
import ProjectTeam from './components/project-team';
import TransferOwnershipModal from './components/project-team/components/TransferOwnershipModal';
import AddTeamMember from './components/add-team-member';
import permissionService from 'services/api/permission/permissionService';
import eventManagementService from 'services/api/event-managment/eventManagementService';
import ProjectDetailsLeftInfo from './components/project-details-left-info';
import ContributionReportModal from './components/contribution-report';
import Location from './components/location';
import EditShowroom from './components/edit-showroom';
import { EventOrganizerSwitchContext } from 'App';

function ProjectDetail({
  project,
  editorSwitch,
  onCloseParent,
  isShowroom,
}: {
  project: Project;
  editorSwitch: boolean;
  onCloseParent: () => void;
  isShowroom: boolean | undefined;
}) {
  const projectCard = toProjectCardProps(project);
  const {
    info,
    members,
    owner,
    projectLinks,
    showroom: statusShowroom,
    status: projectStatus,
    opportunities: projectOpportunities,
    challenge,
  } = projectCard;
  const svc = useUuiContext();
  const eventOrganizerSwitch = useContext(EventOrganizerSwitchContext);
  const { userInfo } = useUserInfo();
  const [addTeamMemberVisible, setAddTeamMemberVisible] = useState(false);
  const [selectedTeamMemberForEdit, setSelectedTeamMemberForEdit] = useState<
    IProjectMember | undefined
  >();
  const [confirmationWindow, setConfirmationWindow] = useState(false);
  const [value, setValue] = useState<IProjectMember>();
  const [event, setEvent] = useState<EventResponse | null>(null);
  const getName = (item: IProjectMember) =>
    `${item.firstName} ${item.lastName}`;

  const { data: permissions } = useQuery(
    [QUERY_KEYS.PROJECTS.PERMISSIONS],
    () => permissionService.getMembershipPermissions(),
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        useCloseBlocker(svc);
        svc.uuiNotifications
          .show((props) => (
            <DefaultError notificationProps={props} error={error} />
          ))
          .catch(() => null);
      },
    }
  );

  useEffect(() => {
    if (!challenge?.challengeId) return;

    const getEventData = async () => {
      try {
        const event = await eventManagementService.getEvent(
          challenge.challengeId
        );
        setEvent(event);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    getEventData();
  }, [challenge?.challengeId]);

  const userRoleInProject = getProjectRole(
    members.find((member) => member.externalId === userInfo?.externalId)
      ?.position
  );

  const userPermissions =
    userRoleInProject && permissions
      ? permissions?.[userRoleInProject as keyof ProjectPermissions]
      : [];

  const userHasEditPermission =
    editorSwitch || eventOrganizerSwitch || userInfo?.id === owner.userId;
  const canModifyApplicants =
    userHasEditPermission ||
    [
      'SCRUM_MASTER',
      'PRODUCT_MANAGER',
      'PROJECT_OWNER',
      'SOLUTION_ARCHITECT',
    ].includes(userRoleInProject ?? '');
  const isProjectInShowroom =
    statusShowroom.status === ProjectShowroomStatus.IN_SHOWROOM;
  const projectHasActiveShowroomRequest =
    statusShowroom.status === ProjectShowroomStatus.SHOWROOM_REQUESTED;
  const projectHasInitialORActiveStatus =
    projectStatus === EnumProjectStatus.ACTIVE ||
    projectStatus === EnumProjectStatus.INITIAL;
  const membersDataSource = useAsyncDataSource(
    {
      api: async () =>
        project.members.filter(
          (member: IProjectMember) => member.position !== ProjectPosition.PO
        ),
    },
    [members]
  );
  const canBeMarkedForDeletion = members.length === 1;
  const sortedTeamMembers = sortMembers(members);
  const uniqueLocations = getUniqMemberLocations(sortedTeamMembers);
  const showroomMessage =
    userInfo?.id === owner.userId ? 'Your' : `${owner.fullName}'s`;
  const onTeamMemberEdit = (member: IProjectMember) => {
    setSelectedTeamMemberForEdit(member);
    setAddTeamMemberVisible(true);
  };
  const projectLink = `${encodeURI(
    process.env.REACT_APP_BASE_URL as string
  )}dashboard/project/${info.id}`;

  const eventOrganizersEmails =
    event?.organizers?.length &&
    event.organizers.map((user) => user.email).join(';');

  const emailRecipient =
    event?.supportEmail ||
    eventOrganizersEmails ||
    'SupportGarageDashboard@epam.com';

  const emailSubject = `Consultancy request for "${project.name}"${
    event ? ` in "${event.name}"` : ''
  }`;
  const emailBody = `I would like to ask for consultancy for project "${
    project.name
  }"${
    event ? ` participating in the event "${event.name}"` : ''
  } at Garage Dashboard.%0A%0AProject link: (${projectLink})${
    event ? `%0AEvent link: (${event.homeSite})` : ''
  }%0A%0A(Please specify your request, indicating if you would like to get consultancy from a subject matter expert or technical guidance for your project, and in which area)`;

  return (
    <FlexRow cx="project-detail-container" alignItems="top">
      <div className="project-detail-left">
        <ProjectDetailsLeftInfo
          project={projectCard}
          userHasEditPermission={
            userHasEditPermission ||
            userPermissions?.includes(ProjectActions.EDIT_STATUS)
          }
          projectHasActiveShowroomRequest={projectHasActiveShowroomRequest}
          onCloseParent={onCloseParent}
          canBeMarkedForDeletion={canBeMarkedForDeletion}
        />
      </div>
      <FlexSpacer />

      <Panel cx="project-detail-right">
        <FlexRow>
          <FlexSpacer />
          {userHasEditPermission && projectHasInitialORActiveStatus && (
            <Button
              cx="project-button"
              caption="Ask Consultancy"
              size="30"
              fill="white"
              color="blue"
              href={`mailto:${emailRecipient}?subject=${emailSubject}&body=${emailBody}`}
            />
          )}
          {(userHasEditPermission ||
            userPermissions?.includes(ProjectActions.EDIT_SHOWROOM)) && (
            <ShowroomButtons
              info={info}
              links={projectLinks}
              projectStatus={projectStatus}
              statusShowroom={statusShowroom}
              projectHasActiveShowroomRequest={projectHasActiveShowroomRequest}
              isShowroom={isShowroom}
            />
          )}
          {(userHasEditPermission ||
            userPermissions?.includes(ProjectActions.EDIT_PROJECT)) && (
            <Button
              cx="project-button"
              icon={editIcon}
              color="blue"
              size="30"
              caption="Edit"
              onClick={() => {
                svc.uuiModals
                  .show((modalProps: IModal<boolean>) =>
                    isProjectInShowroom ? (
                      <EditShowroom
                        modalProps={modalProps}
                        project={project}
                        info={info}
                        links={projectLinks}
                        isProjectInShowroom={isProjectInShowroom}
                        editorSwitch={editorSwitch}
                      />
                    ) : (
                      <UpdateProject
                        modalProps={modalProps}
                        project={project}
                        editorSwitch={editorSwitch}
                      />
                    )
                  )
                  .catch(() => null);
              }}
            />
          )}
        </FlexRow>
        {(userHasEditPermission ||
          userPermissions?.includes(ProjectActions.EDIT_SHOWROOM)) &&
          statusShowroom.status ===
            ProjectShowroomStatus.SHOWROOM_REQUESTED && (
            <FlexRow cx="project-detail-pending-showroom-request">
              <div className="showroom-clockIcon">
                <img
                  src={schedule}
                  alt="schedule"
                  className="showroom-clockIcon"
                />
              </div>
              <div>
                <Text>
                  {showroomMessage} request for the Showroom is being processed.
                  Please await a message from the Editor.
                </Text>
              </div>
            </FlexRow>
          )}
        <ProjectDetailsOpportunities
          userHasEditPermission={
            userHasEditPermission ||
            userPermissions?.includes(ProjectActions.EDIT_OPPORTUNITY)
          }
          projectCard={projectCard}
          canModifyApplicants={canModifyApplicants}
        />
        <FlexRow cx="project-detail-right__teams">
          <Text
            cx={'section-title'}
            fontSize="18"
            lineHeight="24"
            size="18"
            font="museo-sans"
          >
            Team
          </Text>
          {!addTeamMemberVisible &&
            (userHasEditPermission ||
              userPermissions?.includes(ProjectActions.EDIT_MEMBERS)) && (
              <Button
                fill="white"
                color="blue"
                caption="+ Add"
                size={'24'}
                onClick={() => setAddTeamMemberVisible(true)}
                cx="project-detail-add-button"
              />
            )}
          {(userHasEditPermission ||
            userPermissions?.includes(ProjectActions.EDIT_MEMBERS)) && (
            <Button
              fill="white"
              color="blue"
              caption="Contribution Report"
              size="24"
              onClick={() =>
                svc.uuiModals
                  .show<string>((props) => (
                    <ContributionReportModal
                      {...{ projectId: project.id, modalProps: props }}
                    />
                  ))
                  .catch(() => null)
              }
              cx="project-detail-contribute-button"
            />
          )}
        </FlexRow>
        {addTeamMemberVisible && !selectedTeamMemberForEdit?.externalId && (
          <AddTeamMember
            setAddTeamMemberVisible={setAddTeamMemberVisible}
            projectId={info.id}
            membersLength={members.length}
            projectTeam={sortedTeamMembers}
            selectedTeamMemberForEdit={selectedTeamMemberForEdit}
            opportunities={projectOpportunities}
            onCancel={() => {
              setSelectedTeamMemberForEdit(undefined);
              setAddTeamMemberVisible(false);
            }}
          />
        )}
        {sortedTeamMembers?.map((member) => (
          <React.Fragment key={member.id}>
            <ProjectTeam
              onEditClick={onTeamMemberEdit}
              hasEditPermission={
                userHasEditPermission ||
                userPermissions?.includes(ProjectActions.EDIT_MEMBERS)
              }
              canUserTransferOwnership={userHasEditPermission}
              userInfo={userInfo}
              projectId={info.id}
              member={member}
              value={value as IProjectMember}
              getName={getName}
              onValueChange={(newValue) => {
                setValue(newValue);
                setConfirmationWindow(true);
                confirmationWindow &&
                  svc.uuiModals.show((props) => (
                    <TransferOwnershipModal
                      modalProps={props}
                      firstName={newValue?.firstName}
                      lastName={newValue?.lastName}
                      projectId={info.id}
                      userId={newValue?.externalId}
                      setConfirmationWindow={setConfirmationWindow}
                      setValue={setValue}
                    />
                  ));
              }}
              dataSource={membersDataSource}
            />
            {addTeamMemberVisible &&
              selectedTeamMemberForEdit?.externalId === member.externalId && (
                <AddTeamMember
                  setAddTeamMemberVisible={setAddTeamMemberVisible}
                  projectId={info.id}
                  membersLength={members.length}
                  selectedTeamMemberForEdit={selectedTeamMemberForEdit}
                  projectTeam={sortedTeamMembers}
                  opportunities={projectOpportunities}
                  onCancel={() => {
                    setSelectedTeamMemberForEdit(undefined);
                    setAddTeamMemberVisible(false);
                  }}
                />
              )}
          </React.Fragment>
        ))}
        <Location uniqueLocations={uniqueLocations} />
      </Panel>
    </FlexRow>
  );
}

export default ProjectDetail;
